import React from "react";

export default function AboutMeDetalis() {
  return (
    <>
      <section className="section-2 my-5">
        <div className="container">
          <h1 className="text-light" style={{fontSize:"50px"}}>About Me</h1>
          <p className="text-light mx-5 col-10 m" style={{textTransform:"capitalize",fontSize:"25px",fontFamily:"serif"}}>
          I am Brijesh Vekaria. I am a front-end developer and web designer. I have many skills like HTML, CSS, JS, React JS, Bootstrap, jQuery, Express etc. I have 1 year experience on this language. I create many projects. I handle many client side projects.
          </p>
        </div>
      </section>
    </>
  );
}
